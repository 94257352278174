import consumer from "./consumer";
import CableReady from "cable_ready";

document.addEventListener("turbo:load", function () {
  if (document.head.querySelector("meta[name=current_user]")?.content) {
    consumer.subscriptions.create("NotyfChannel", {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations);
      },
    });
  }
});
