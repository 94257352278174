import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["name", "baseUnit", "operator", "examples"];

  connect() {}

  unitChanged(event) {
    event.preventDefault();
    var unit = this.units().find(({ name }) => name === event.target.value);
    this.fillForm(unit);
  }

  fillForm(unit) {
    this.nameTarget.value = unit.name;
    this.baseUnitTarget.value = unit.base_unit;
    this.operatorTarget.value = unit.operator;
    this.examplesTarget.textContent = unit?.examples
  }

  units() {
    return [
      {
        name: "Piece",
        base_unit: "piece",
        operator: "Pc",
      },
      {
        name: "Dozen",
        base_unit: "dozen",
        operator: "Dz",
      },
      {
        name: "Mass",
        base_unit: "kilogram",
        operator: "Kg",
        examples: "eg: Kilogram, Grams"
      },
      {
        name: "Length",
        base_unit: "metre",
        operator: "m",
        examples: "eg: Metre, Centimetre"
      },
      {
        name: "Volume",
        base_unit: "litre",
        operator: "l",
        examples: "eg: mililitre, Litre"
      }
    ];
  }
}
