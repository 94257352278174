import { Controller } from "@hotwired/stimulus";
import { TemplateInstance } from "@github/template-parts";

export default class extends Controller {
  static targets = ["template", "items", "total"];

  connect() {
    this.updateTotal();
  }  

  async addItem(event) {
    var template = this.cloneController.templateTarget;
  
    var selected = event.target.options[event.target.selectedIndex];
    var response = await fetch(`/products/${selected.value}.json`);
    var data = await response.json();
    this.cloneController.append({
      product_combo_id: selected.value,
      name: data.name,
      buying_price: data.price.buying_price,
      quantity: 1
    });
    this.updateTotal();
  }

  removeItem(event) {
    const item = event.target.closest(".item");
    if (item) {
      item.remove();
      this.updateTotal();
    }
  }  

  quantityChanged(event) {
    this.updateTotal();
  }

  get cloneController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "clone"
    );
  }

  updateTotal() {
    let total = 0;
    this.itemsTarget.querySelectorAll(".item").forEach((item) => {
      const quantity = item.querySelector('[name$="[quantity]"]').value;
      const buyingPrice = item.querySelector('[name$="[buying_price]"]').value;
      total += quantity * buyingPrice;
    });
    this.totalTarget.textContent = total.toFixed(2);
  }
}
