import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["quantitySummary", "destroyField", "quantityField", "template", "wrapper", "submitButton"]

  connect() {
    this.parentQuantity = parseInt(this.data.get("parentQuantity"))
    this.updateQuantitySummary()
    this.variantIndex = this.wrapperTarget.querySelectorAll(".subvariant-form").length
  }

  updateQuantitySummary() {
    let totalQuantity = 0
    for (const input of this.quantityFieldTargets) {
      const subvariantForm = input.closest(".subvariant-form")
      const destroyField = subvariantForm.querySelector(".destroy-field")
      if (destroyField.value !== "1") {
        totalQuantity += parseInt(input.value) || 0
      }
    }

    const difference = this.parentQuantity - totalQuantity
    this.quantitySummaryTarget.textContent = `Current total: ${totalQuantity} (${difference > 0 ? difference + ' remaining' : Math.abs(difference) + ' over'})`
    this.quantitySummaryTarget.className = `alert ${difference === 0 ? 'alert-success' : 'alert-warning'} quantity-summary`

    return totalQuantity
  }

  handleQuantityChange(event) {
    if (event.target.classList.contains("quantity-field")) {
      this.updateQuantitySummary()
    }
  }

  handleSubmit(event) {
    const totalQuantity = this.updateQuantitySummary()
    if (totalQuantity !== this.parentQuantity) {
      alert(`Total quantity (${totalQuantity}) must equal parent variant quantity (${this.parentQuantity})`)
    } else {
      const form = this.element.closest("form")
      if (form) form.submit()
    }
  }  

  add(event) {
    event.preventDefault()

    const content = this.templateTarget.content.cloneNode(true)
    content.querySelectorAll("input, select").forEach(input => {
      const nameAttr = input.getAttribute("name")
      if (nameAttr) input.setAttribute("name", nameAttr.replace(/{{index}}/, this.variantIndex))
    })

    content.querySelector(".remove-variant").addEventListener("click", (e) => {
      e.preventDefault()
      e.target.closest(".subvariant-form").style.display = "none"
      e.target.closest(".subvariant-form").querySelector(".destroy-field").value = "1"
      this.updateQuantitySummary()
    })

    this.wrapperTarget.appendChild(content)
    this.variantIndex++
  }

  removeSubvariant(event) {
    event.preventDefault()
    const subvariantForm = event.target.closest(".subvariant-form")
    if (subvariantForm) {
      subvariantForm.querySelector(".destroy-field").value = "1"
      subvariantForm.style.display = "none"
      this.updateQuantitySummary()
    }
  }
}
