import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"
import TomSelect_remove_button from 'tom-select/dist/js/plugins/remove_button.js';

TomSelect.define('remove_button', TomSelect_remove_button);

require("tom-select/dist/css/tom-select.bootstrap4.css")

export default class extends Controller {
  static targets = ["selectable", "salesTable"]

  connect() {
    this.select = new TomSelect(this.selectableTarget, {
      plugins: ['remove_button'],
      persist: false,
      closeAfterSelect: true,
      createOnBlur: true,
      create: false,
      onChange: this.handleCustomerChange.bind(this)
    });
  }

  handleCustomerChange() {
    const customerId = this.select.getValue();
    if (customerId) {
      this.fetchSales(customerId);
    } else {
      this.clearSalesTable();
    }
  }

  fetchSales(customerId) {
    fetch(`/invoice_payments/sales?customer_id=${customerId}`)
      .then(response => response.json())
      .then(data => {
        this.renderSalesTable(data);
      })
      .catch(error => console.error('Error fetching sales:', error));
  }

  renderSalesTable(sales) {
    const tableBody = this.salesTableTarget.querySelector('tbody');
    tableBody.innerHTML = ''; // Clear existing rows

    sales.forEach((sale, index) => {
      const row = document.createElement('tr');
      row.innerHTML = `
        <td>
          <input type="radio" name="payment[payable_id]" id="sale_${sale.id}" value="${sale.id}" ${index === 0 ? 'checked' : ''}>
          <input type="hidden" name="payment[payable_type]" value="Sale">
        </td>
        <td>${this.renderSaleDetails(sale)}</td>
        <td class="text-center">${sale.due_date ? this.formatDate(sale.due_date) : ''}</td>
        <td class="text-right">KSH ${sale.total_amount}</td>
        <td class="text-right">KSH ${sale.balance_amount}</td>
      `;
      tableBody.appendChild(row);
    });
  }

  clearSalesTable() {
    const tableBody = this.salesTableTarget.querySelector('tbody');
    tableBody.innerHTML = '';
  }

  formatDate(rawDate) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    const formattedDate = new Date(rawDate).toLocaleString('en-US', options);
    return formattedDate;
  }

  renderSaleDetails(sale) {
    return `
      ${sale.reference} <br>
      <span class="text-muted small">${this.formatDate(sale.date)}</span>
      <br>
      <small class="text-muted"> ${sale.user ? sale.user.first_name : ''}</small> for
      <span class="text-navy">${sale.biller ? sale.biller.name : ''}</span>
      <br>
      <a href="${this.saleLineItemsPath(sale.id)}" data-turbo-frame="modal">
        <i class="fas fa-eye"></i> Preview
      </a>
    `;
  }
  
  saleLineItemsPath(saleId) {
    return `/sales/${saleId}/line_items`;
  }
}
