import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "terms", "message" ]

  displayMessage() {
    if (this.termsTarget.value == 'Due') {
      this.messageTarget.textContent = 'This option DOES NOT reserve stock.'
      this.messageTarget.classList.remove('d-none')
    } else {
      this.messageTarget.textContent = ''
      this.messageTarget.classList.add('d-none')
    }
  }
}
