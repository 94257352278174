import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "costWrapper", "cost", "cod" ]

  connect() {
    this.toggleShippingCost();
  }

  toggleShippingCost() {
    if (this.codTarget.checked) {
      this.costTarget.value = 0;
      this.costWrapperTarget.classList.add('d-none');
    } else {
      this.costWrapperTarget.classList.remove('d-none');
    }
  }
}
