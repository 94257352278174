import { Controller } from "@hotwired/stimulus"
import TomSelect  from "tom-select"
import TomSelect_remove_button from 'tom-select/dist/js/plugins/remove_button.js';

TomSelect.define('remove_button', TomSelect_remove_button);

require("tom-select/dist/css/tom-select.bootstrap4.css")

export default class extends Controller {
  static targets = ["selectable"]
  connect(){
    this.select = new TomSelect(this.selectableTarget, {
      plugins: ['remove_button'],
      persist: false,
      closeAfterSelect: true,
      createOnBlur: true,
      create: false,
      onChange: () => { }
    })
  }

}
