
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import * as Trix from "trix"
import * as Turbo from "@hotwired/turbo-rails"
import LocalTime from "local-time"
import "mapkick/bundle"
// import "./inspinia"
import 'bootstrap'
import 'data-confirm-modal'
import "channels"
import "controllers"
import "chartkick/chart.js";
import "metismenu"
import "jquery-slimscroll"
import 'bootstrap-switch/dist/js/bootstrap-switch'
import moment from 'moment';
import "@fortawesome/fontawesome-free/css/all"
import dataTable from 'datatables.net-bs4'
dataTable(window, $)

Rails.start()
ActiveStorage.start()
LocalTime.start()
window.Rails = Rails;

document.addEventListener("turbo:load", () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })
  $('.form-switch input[type="checkbox"]').bootstrapSwitch();
})

// custom js
window.initMap = function (...args) {
  const event = document.createEvent("Events");
  event.initEvent("google-maps-callback", true, true);
  event.args = args;
  window.dispatchEvent(event);
};

function toggleDropdown(element) {
  var dropdown = new Dropdown(element);
  dropdown.toggle();
}

document.addEventListener('turbo:load', function() {
  var dropdown_buttons = document.querySelectorAll('[data-toggle="dropdown-collapse"]');
  dropdown_buttons.forEach(function(element) {
    element.children[0].addEventListener('click', function(event) {
      event.preventDefault();
      element.children[1].classList.toggle("in")
    });
  });
});

window.moment = moment;


window.printElement = function (elem, append, delimiter) {
  var domClone = elem.cloneNode(true);

  var $printSection = document.getElementById("printSection");
  if (!$printSection) {
    var $printSection = document.createElement("div");
    $printSection.id = "printSection";
    document.body.appendChild($printSection);
  }

  if (append !== true) {
    $printSection.innerHTML  = "";
  } else if (append === true) {
    if (typeof delimiter === "string") {
      $printSection.innerHTML += delimiter;
    } else if (typeof delimiter === "object") {
      $printSection.appendChild(delimiter);
    }
  }

  $printSection.appendChild(domClone);

  window.print();
};

window.onbeforeprint = function(){
 var printSection = document.getElementById("printSection");
  printSection.style.display = "block"
}

window.onafterprint = function(){
 var printSection = document.getElementById("printSection");
  printSection.style.display = "none"
}
