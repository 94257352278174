import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['role'];

  toggleFields(event) {
    const selectedRole = event.target.value;
    const allTargets = this.element.querySelectorAll('[data-membership-role-target]');
    
    allTargets.forEach((target) => {
        if (target.dataset.membershipRoleTarget.includes(selectedRole)) {
            target.classList.remove('d-none');
        } else {
            target.classList.add('d-none');
        }
    });
  }
}