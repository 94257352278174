import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['form', 'progress'];

  connect() {
    console.log("Progress Contoller")
    this.frameLoadEventListener = (event) => {
        if (this.formTarget == event.target) {
            this.progressTarget.reload() 
        }
    }
      document.addEventListener("turbo:frame-load", this.frameLoadEventListener);
    }

    disconnect() {
        document.removeEventListener("turbo:frame-load", this.frameLoadEventListener);
    }    

}