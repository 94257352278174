import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["productsContainer", "item"];

  connect() {
    this.loadLayoutChoice();
  }

  gridView() {
    this.itemTargets.forEach((element) => {
      element.classList.remove("col-md-12");
      element.classList.add("col-md-4");
    });
    this.saveLayoutChoice("grid");
  }

  listView() {
    this.itemTargets.forEach((element) => {
      element.classList.remove("col-md-4");
      element.classList.add("col-md-12");
    });
    this.saveLayoutChoice("list");
  }

  saveLayoutChoice(choice) {
    localStorage.setItem("productLayout", choice);
  }

  loadLayoutChoice() {
    const savedLayout = localStorage.getItem("productLayout");
    if (savedLayout) {
      this.productsContainerTarget.classList.add(savedLayout);
    }
  }
}
