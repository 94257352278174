import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["output"];

  taxsham() {
    Turbo.visit("/sales?taxed=true");
  }

  flagged() {
    Turbo.visit("/sales?flagged=true");
  }
}
