import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "payment", "reference", "template" ]

  static values = {
    persisted: Boolean
  }

  connect() {

  }

  splitPayment(){
   if(!this.persistedValue){
     this.referenceTargets[1].value = this.nextReference(this.referenceTargets[1].value)
   }
  }

  nextReference(reference){
    var splitted = reference.split("/")
    var number = splitted[splitted.length -1 ]

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    splitted[splitted.length -1]  = zeroPad(parseInt(number) + 1, 4)

    return splitted.join("/");
  }
}
