import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["returned", "unitCost", "subCost"];

  connect() {
    this.subCostTarget.textContent = this.computeSubCost()
  }

  computeSubCost() {
   return this.returnedTarget.value * parseFloat(this.unitCostTarget.textContent)
  }

  setSubCost(event) {
    event.preventDefault();
    this.subCostTarget.textContent = this.computeSubCost()

    this.highlightLineItem(event)
  }

  removeAssociation(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
  }

  highlightLineItem(event){
    event.preventDefault()
    // Highlight line Item with a color
    var  item = event.target.closest(".nested-fields");
    if(this.returnedTarget.value > 0){
      item.classList.add("bg-warning", "text-dark")
    }else{
      item.classList.remove("bg-warning", "text-dark")
    }
  }
}
