import { Controller } from "@hotwired/stimulus";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

export default class extends Controller {
  static targets = [];

  initialize() {
    this.notyf = new Notyf({duration: 4000});
  }

  connect() {
    document.addEventListener("notification", this.notify);
    // this.notyf.success('Hello')
  }

  disconnect() {
    document.removeEventListener("notification", this.notify);
  }

  notify = (event) => {
    const { message } = event.detail;
    this.notyf.success(`${message}`);
  };
}
