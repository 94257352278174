import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "page"]

  search(){
    clearTimeout(this.setTimeout)

    this.timeout = setTimeout( () => {
      this.formTarget.requestSubmit()
    }, 200);
  }

  paginate(event){
    event.preventDefault()
    if(event.target.dataset.page){
      this.pageTarget.value = event.target.dataset.page
      this.search()
    }
  }
}
