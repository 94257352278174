import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {

  static targets = [ "status", "verified" ]

  connect() {
    console.log("Modal connected")
    this.modal = new Modal(this.element)
    this.modal.hide()
    this.showVerifiedAttributes();
  }

  showVerifiedAttributes(){
    if (this.statusTarget.value === "verified") {
      this.verifiedTarget.classList.remove("d-none")
    } else {
      this.verifiedTarget.classList.add("d-none")
    }
  }

}
