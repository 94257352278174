import { Controller } from "@hotwired/stimulus"

let isPasswordVisible = false;
let isNewPasswordVisible = false;
let isPasswordConfirmationVisible = false;
let isLoginPasswordVisible = false;

export default class extends Controller {

    static targets = [
        "currentPassword",
        "newPassword",
        "newPasswordConfirmation",
        "loginPassword"
    ]

    connect(){}

    toggleCurrentPassword() {
        const closedEyeIcon = document.querySelector("#closed-eye");
        const openEyeIcon = document.querySelector("#open-eye");
    
        if (!isPasswordVisible) {
            closedEyeIcon.classList.add("d-none");
            openEyeIcon.classList.remove("d-none");
            this.currentPasswordTarget.type = "text";
        } else {
            closedEyeIcon.classList.remove("d-none");
            openEyeIcon.classList.add("d-none");
            this.currentPasswordTarget.type = "password";
        }
        isPasswordVisible = !isPasswordVisible;
    }


    toggleNewPassword() {
        const closedEyeIcon = document.querySelector("#closed-eye-new");
        const openEyeIcon = document.querySelector("#open-eye-new");
    
        if (!isNewPasswordVisible) {
            closedEyeIcon.classList.add("d-none");
            openEyeIcon.classList.remove("d-none");
            this.newPasswordTarget.type = "text";
        } else {
            closedEyeIcon.classList.remove("d-none");
            openEyeIcon.classList.add("d-none");
            this.newPasswordTarget.type = "password";
        }
        isNewPasswordVisible = !isNewPasswordVisible;
    }

    togglePasswordConfirmation() {
        const closedEyeIcon = document.querySelector("#closed-eye-conf");
        const openEyeIcon = document.querySelector("#open-eye-conf");
    
        if (!isPasswordConfirmationVisible) {
            closedEyeIcon.classList.add("d-none");
            openEyeIcon.classList.remove("d-none");
            this.newPasswordConfirmationTarget.type = "text";
        } else {
            closedEyeIcon.classList.remove("d-none");
            openEyeIcon.classList.add("d-none");
            this.newPasswordConfirmationTarget.type = "password";
        }
        isPasswordConfirmationVisible = !isPasswordConfirmationVisible;
    }

    toggleLoginPassword() {
        const closedEyeIcon = document.querySelector("#closed-eye-login");
        const openEyeIcon = document.querySelector("#open-eye-login");
    
        if (!isLoginPasswordVisible) {
            closedEyeIcon.classList.add("d-none");
            openEyeIcon.classList.remove("d-none");
            this.loginPasswordTarget.type = "text";
        } else {
            closedEyeIcon.classList.remove("d-none");
            openEyeIcon.classList.add("d-none");
            this.loginPasswordTarget.type = "password";
        }
        isLoginPasswordVisible = !isLoginPasswordVisible;
    }

}