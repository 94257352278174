import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "percentage",
    "amountOutput",
    "totalAmount",
    "percentageAllocate",
  ];

  connect() {}

  anualAllocateChanged(event) {
    this.percentageAllocateTarget.classList.toggle("d-none");
    this.amountOutputTargets.forEach((item, i) => {
      item.classList.toggle("d-none");
    });
  }

  totalAmountChanged() {
    this.percentageTargets.forEach((amountTarget, i) => {
      var outputTarget = this.amountOutputTargets.find(
        (target) => target.id == amountTarget.dataset.biller
      );

      this.showOutput(outputTarget, amountTarget.value);
    });
  }

  changed(event) {
    var outputTarget = this.amountOutputTargets.find(
      (target) => target.id == event.target.dataset.biller
    );

    this.showOutput(outputTarget, event.target.value);
  }

  showOutput(target, percentage) {
    target.textContent = (
      (percentage / 100) *
      this.totalAmountTarget.value
    ).toLocaleString();
  }
}
