import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "total", "subCost", "balance", "surcharge", "saleTotal", "invoiceTotal", "invoicePaid", "invoiceBalance" ]

  setTotal(){
    const documentType = this.element.dataset.returnTotalDocumentType;

    if (documentType === "sale-receipt") {

      const saleTotal = parseFloat(this.saleTotalTarget.innerHTML);

      const returnedTotal = this.subCostTargets.reduce((total, subcost) => {
        return total + parseFloat(subcost.textContent);
      }, 0);


      this.totalTarget.textContent = returnedTotal;

      const surcharge = parseFloat(this.surchargeTarget.value);

      const balance = saleTotal - returnedTotal - surcharge;

      this.balanceTarget.textContent = balance;

    } else if (documentType === "invoice") {
      const invoicePaidTotal = parseFloat(this.invoicePaidTarget.innerHTML);
      const invoiceTotal = parseFloat(this.invoiceTotalTarget.innerHTML);

      const returnedTotal = this.subCostTargets.reduce((total, subcost) => {
        return total + parseFloat(subcost.textContent);
      }, 0);

      this.totalTarget.textContent = returnedTotal;

      if (invoicePaidTotal > 0) {
        const surcharge = parseFloat(this.surchargeTarget.value);
        const adjustBal  = invoiceTotal  -  returnedTotal;
        this.invoiceBalanceTarget.textContent  = invoicePaidTotal - adjustBal - surcharge;
      } else {
        const new_balance = invoiceTotal - returnedTotal;
        this.invoiceBalanceTarget.textContent = new_balance;
      }
    }
  }
}
