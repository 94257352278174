import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    url: String
  }

  link(event){
    console.log("clicked")
    window.location = this.urlValue
  }
}
