import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ['modalBody']

  connect() {
    this.refreshData()
    this.interval = setInterval(() => this.refreshData(), 3 * 60 * 60 * 1000)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  refreshData() {
    fetch('/unverified_transfers.json')
      .then(response => response.json())
      .then(data => {
        const currentUserID = window.currentUserID;
        this.modalBodyTarget.innerHTML = this.renderTransfers(data, currentUserID);

        if (data.some(transfer => transfer.creator.id === currentUserID || transfer.sender.id === currentUserID)) {
          this.showModal();
        }
      })
  }

  renderTransfers(transfers, currentUserID) {
    const tableRows = transfers.map(transfer => {
      const creatorID = transfer.creator ? transfer.creator.id : null;
      const senderID = transfer.sender ? transfer.sender.id : null;
  
      if (creatorID === currentUserID || senderID === currentUserID) {
        return `
          <tr>
            <td>${transfer.reference} <br>
              ${this.formatDate(transfer.transfer_date)}
            </td>
            <td>${transfer.from_warehouse ? transfer.from_warehouse.name : ''}</td>
            <td>${transfer.creator ? transfer.creator.first_name : ''}</td>
            <td>${transfer.sender ? transfer.sender.first_name : ''}</td>
            <td><span class="badge badge-${transfer.verification_status.toLowerCase()}">${transfer.verification_status}</span></td>
          </tr>
        `;
      } else {
        return '';
      }
    }).join('');

    return `
      <table class="table">
        <thead>
          <tr>
            <th>Reference</th>
            <th>From warehouse</th>
            <th>Creator </th>
            <th>Sender</th>
            <th>Verification Status</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table>
    `;
  }

  showModal() {
    const modal = new Modal(document.getElementById('transferModal'))
    modal.show()
  }

  formatDate(rawDate) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    const formattedDate = new Date(rawDate).toLocaleString('en-US', options);
    return formattedDate;
  }
}
