import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggleButton" ]

  connect() {
    this.toggleButtonTarget.addEventListener('click', this.toggleDocumentType.bind(this));
  }

  toggleDocumentType() {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);

    if (params.get('document_type') === 'invoice') {
      params.set('document_type', 'sale-receipt');
    } else {
      params.set('document_type', 'invoice');
    }

    // Update the URL and reload the page with the new document_type
    window.location.search = params.toString();
  }
}
