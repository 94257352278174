import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["btn", "printable", "printSection"]

  connect() {
    console.log('connected')
  }

  print(){
    printElement(this.printableTarget)
  }

}
