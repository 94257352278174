import { Controller } from "@hotwired/stimulus"

import SlimSelect from "slim-select"

require("slim-select/dist/slimselect.min.css")

// Connects to data-controller="addable-slim-select"
export default class extends Controller {
  static targets = [ "selectable" ]
  static values = {
     selectable: String,
     ajax: Boolean
  }

  connect() {
    this.select = new SlimSelect({
      select: this.selectableTarget,
      placeholder: `Add email address`,
      // ajax: this.ajaxValue ? this.search : false,
      addable: function (value) {
        // return false or null if you do not want to allow value to be submitted
        if (value === 'bad') {return false}
  
        // Return the value string
        return value // Optional - value alteration // ex: value.toLowerCase()
      }
    })
  }
}
