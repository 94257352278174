import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

  static targets = [ "all", "few", "checkbox"]

  connect(){
  }

  all(){
    this.allTarget.classList.toggle("d-none")
    this.fewTarget.classList.add("d-none")

    this.checkboxTarget.checked = true
  }

  few(){
    this.fewTarget.classList.toggle("d-none")
    this.allTarget.classList.add("d-none")

    this.checkboxTarget.checked = false
  }

}
