import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.shouldShowAlert()) {
      this.element.style.display = "block"
    } else {
      this.element.style.display = "none"
    }
  }

  closeAlert() {
    this.element.style.display = "none"
    this.setAlertClosedForToday()
  }

  shouldShowAlert() {
    const lastClosedTime = this.getLastClosedTime()
    if (!lastClosedTime) {
      return true
    }

    const currentTime = new Date()
    const timeDifference = currentTime - lastClosedTime
    // Set the interval (in milliseconds) for how often to show the alert (1 day in this case)
    const alertInterval = 24 * 60 * 60 * 1000

    return timeDifference >= alertInterval
  }

  getLastClosedTime() {
    const lastClosedTime = localStorage.getItem("lastClosedTime")
    if (lastClosedTime) {
      return new Date(JSON.parse(lastClosedTime))
    }
    return null
  }

  setAlertClosedForToday() {
    const currentTime = new Date()
    localStorage.setItem("lastClosedTime", JSON.stringify(currentTime))
  }
}