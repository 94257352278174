import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "wrapper"]

  connect() {
    this.variantIndex = this.wrapperTarget.querySelectorAll('.variant-fields').length
  }

  add(event) {
    event.preventDefault()
    
    const content = this.templateTarget.content.cloneNode(true)
    
    const inputs = content.querySelectorAll('input, select')
    inputs.forEach(input => {
      const nameAttr = input.getAttribute('name')
      if (nameAttr) {
        input.setAttribute('name', nameAttr.replace(/{{index}}/, this.variantIndex))
      }
    })

    const removeButton = content.querySelector('.remove-variant')
    if (removeButton) {
      removeButton.addEventListener('click', (e) => {
        e.preventDefault()
        e.target.closest('.variant-fields').remove()
      })
    }

    this.wrapperTarget.appendChild(content)
    this.variantIndex++
  }

  remove(event) {
    event.preventDefault()
    event.target.closest('.variant-fields').remove()
  }
}