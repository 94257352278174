import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "subcategory" ];

    connect() {
      this.updateSubcategories();

      console.log("ProductCategoryController connected");
    }
  
    updateSubcategories() {
      const categoryId = this.element.value;
      fetch(`/categories/${categoryId}/sub_categories.json`)
        .then(response => response.json())
        .then(data => {
          // Clear existing options
          this.subcategoryTarget.innerHTML = "";
  
          // Add new options
          data.forEach(subcategory => {
            const option = document.createElement("option");
            option.value = subcategory.id;
            option.text = subcategory.name;
            this.subcategoryTarget.appendChild(option);
          });
        });
    }
  
    // This function is called when the category select is changed
    categoryChanged() {
      console.log("Category changed");
      this.updateSubcategories();
    }
  
    // Getter for the subcategory element
    get subcategorySelect() {
      console.log("Getting subcategory select");
      return this.subcategoryTarget;
    }
  
}