import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "subdomain", "subdomainInput", "business" ]

  connect() {
    this.subdomainTarget.textContent = "https://quadvendor.com"
  }

  subdomainInputChange(event){
    event.preventDefault()
    this.subdomainTarget.textContent = `https://${this.getFirtName(event.target.value)}.quadvendor.com`
  }

  businessNameChange(event){
    this.subdomainInputTarget.value = this.getFirtName(event.target.value)
    this.subdomainInputChange(event)
  }

  getFirtName(value){
    return value.split(" ")[0]
  }
}
