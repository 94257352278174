import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  export(event) {
    event.preventDefault();

    let currentUrl = window.location.href;
    let updatedUrl = currentUrl + "&format=xlsx";

    let link = document.createElement("a");
    link.href = updatedUrl;
    link.download = "Products_Report.xlsx";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }
}