import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["accountSubcategory"];

    connect() {
        this.hideAccountSubcategory();
    };

    hideAccountSubcategory() {
        this.accountSubcategoryTarget.style.display = "none";
    }

    // showAccountSubcategory based on account category
    updateAccountSubcategory(event) {
        const accountCategoryId = event.target.value;
        const accountSubcategory = this.accountSubcategoryTarget;

        // fetch the account sub categories based on the selected account category
          // and update the options in the select field
        fetch(`/ecommerce_api/v1/categories/${accountCategoryId}`)
            .then(response => response.json())
            .then((data) => {
                const options = data.map((accountSubCategory) => {
                    return `<option value="${accountSubCategory.id}">${accountSubCategory.name}</option>`;
                });
                accountSubcategory.innerHTML = `<select class="form-control">${options.join("")}</select>`;
                accountSubcategory.style.display = "block";
             });



    }

}
        
