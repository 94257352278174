import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";
require("slim-select/dist/slimselect.min.css");

export default class extends Controller {
  static targets = [
    "template",
    "product",
    "items",
    "table",
    "discountAmount",
    "discountPercent",
  ];

  connect() {
    this.product = null;
  }

  addTableRow(event) {
    event.preventDefault();
    const selectedOptions = Array.from(this.productTarget.selectedOptions);
    selectedOptions.forEach(option => {
      const productId = option.value;
      if (productId) {
        this.getProduct(productId);
      }
    });
  }

  get productSelectController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "product-select"
    );
  }

  addAssociation() {
    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      this.itemsTarget.querySelectorAll(".nested-fields").length
    );
    this.templateTarget.insertAdjacentHTML("beforebegin", content);
    this.addFieldValues();
    this.showTable();
  }

  addFieldValues() {
    const items = this.itemsTarget.querySelectorAll(".nested-fields");
    const item = items[items.length - 1];

    if (!item) {
      console.error("Failed to find the newly added item.");
      return;
    }

    const productIdInput = item.querySelector("input[name*='product_id']");
    const productNameInput = item.querySelector("input[name*='product_name']");
    const unitCostInput = item.querySelector("input[name*='unit_cost']");
    const discountPercentageInput = item.querySelector("input[name*='discount_percentage']");
    const discountAmountInput = item.querySelector("input[name*='discount_amount']");
    const priceAfterDiscountInput = item.querySelector("input[name*='price_after_discount']");
    const priceAfterDiscountSpan = item.querySelector(".price-after-discount");

    if (productIdInput) productIdInput.value = this.product.id;
    if (productNameInput) productNameInput.value = this.product.name;
    if (unitCostInput) unitCostInput.value = this.product.price.ecommerce_price;
    if (discountPercentageInput) discountPercentageInput.value = 0;
    if (discountAmountInput) discountAmountInput.value = 0;

    const unitCost = parseFloat(this.product.price.ecommerce_price);
    const discountPercentage = parseFloat(discountPercentageInput.value) || 0;
    const discountAmount = parseFloat(discountAmountInput.value) || 0;

    let priceAfterDiscount = unitCost;

    if (discountPercentage > 0) {
      priceAfterDiscount = unitCost - (unitCost * (discountPercentage / 100));
    } else if (discountAmount > 0) {
      priceAfterDiscount = unitCost - discountAmount;
    }

    // if (priceAfterDiscountInput) priceAfterDiscountInput.value = priceAfterDiscount.toFixed(2);
    // if (priceAfterDiscountSpan) priceAfterDiscountSpan.textContent = priceAfterDiscount.toFixed(2);
  }

  removeAssociation(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
    this.hideTableIfEmpty();
  }

  getProduct(id) {
    Rails.ajax({
      type: "GET",
      url: `/products/${id}.json`,
      success: (data, status, xhr) => {
        this.product = data;
        this.addAssociation();
      },
    });
  }

  showTable() {
    this.tableTarget.classList.remove("d-none");
  }

  hideTableIfEmpty() {
    if (this.itemsTarget.querySelectorAll(".nested-fields").length === 0) {
      this.tableTarget.classList.add("d-none");
    }
  }
}
