import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "productName",
    "productVariantId",
    "variantsModal",
    "variantSelect",
  ];

  connect() {
    setTimeout(() => {
      if (this.variantSelectTarget.options.length > 1) {
        this.showModal(this.variantsModalTarget);
      }
    }, 200);
  }

  openModal(event) {
    event.preventDefault();
    this.showModal(this.variantsModalTarget);
  }

  showModal(item) {
    const modal = new Modal(item);
    modal.show();
  }

  variantChanged(event) {
    event.preventDefault();
    var variantName = event.target.options[event.target.selectedIndex].text;
    var variantId = event.target.value;
    this.addVariantValues(variantId, `(${variantName})`);
  }

  addVariantValues(variantId, variantName) {
    this.productVariantIdTarget.value = variantId;
    var productName = this.productNameTarget.value;
    if (/\(.*\)/.test(productName)) {
      this.productNameTarget.value = productName.replace(/\(.*\)/, variantName);
    } else {
      this.productNameTarget.value = `${productName} ${variantName}`;
    }
  }
}
