import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "message", "output" ];
    
  connect() {

    const length = this.messageTarget.value.length;

    if (length > 160) {
        this.showChargeMessage(length)
    }

    this.messageTarget.addEventListener('input', (e) => {
        const length = e.target.value.length;

        if (length > 160) {
            this.showChargeMessage(length);
        } else {
            this.hideChargeMessage();
        }
    });
    }

    showChargeMessage(length) {
        const chargeMessage = `You are being charged ${(Math.floor(length / 160) + 1)} times for this message.`
        this.outputTarget.innerHTML = chargeMessage;
    }

    hideChargeMessage() {
        this.outputTarget.textContent = `Cost is Ksh 1 per 160 words(Includes spaces, comma, etc.).`
    }

}