import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"
require("slim-select/dist/slimselect.min.css")

export default class extends Controller {
  static targets = ["product"]

  connect() {
    if (this.hasProductTarget) {
      this.select = new SlimSelect({
        select: this.productTarget,
        placeholder: 'Search Product',
        ajax: this.searchProducts.bind(this),
        allowDeselectOption: true,
        searchFilter: (option, search) => {
          return true
        }
      })
    } else {
      console.error("Product target not found")
    }
  }

  searchProducts(query, callback) {
    if (query.length < 3) {
      callback(false)
      return
    }
    Rails.ajax({
      type: "GET",
      url: `/products.json?q=${query}`,
      success: (data, status, xhr) => {
        let options = []
        for (let i = 0; i < data.length; i++) {
          options.push({ text: `${data[i].name} (${data[i].code}) - ${data[i].category.name}`, value: data[i].id.toString() })
        }
        callback(options)
      },
      error: () => callback(false)
    })
  }
}
