import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "address", "lat", "lng" ]

  connect() {
    if (typeof(google) != "undefined") {
      this.initMap()
    }
  }

  initMap(){
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget)
    console.log(this.autocomplete)
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
  }

  placeChanged(){
    var place = this.autocomplete.getPlace()
    if(place.geometry){
      this.latTarget.value = place.geometry.location.lat()
      this.lngTarget.value = place.geometry.location.lng()
     }
  }

  keydown(event){
    if (event.key == "Enter"){
      event.preventDefault()
    }
  }
}
