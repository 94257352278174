import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    console.log("connected")

    var input = document.getElementById("google-location");
    var autocomplete = new google.maps.places.Autocomplete(input);

    this.modal = new Modal(this.element)
    this.modal.show()
  }

}
