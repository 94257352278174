
import { Controller } from "@hotwired/stimulus"
import  Rails  from "@rails/ujs"

export default class extends Controller {
  static targets = [ "product", "items" ]

  connect() {
    this.product = null
  }

  productChange(){
    var product_id = this.productTarget.value
    this.getProduct(product_id)
    setTimeout(()=>{
      console.log(this.product)
    },3000)
    //this.addTableRow(product)
  }


  getProduct(id){
    Rails.ajax({
      type: "GET",
      url: `/products/${id}.json`,
      success: function(data,status,xhr) {
       console.log(status)
        this.product = data
      }
    })
  }

  addTableRow(product){
    var table = this.itemsTarget
    var row = table.insertRow(0)
    row.insertCell(0).innerHTML = "<input value='"+ product +"' name='purchase[items][][product]' class='form-control' >"
    row.insertCell(1).innerHTML = "<input name='purchase[items][][unit_cost]' class='form-control'>"
    row.insertCell(2).innerHTML = "<input name='purchase[items][][quantity]' class='form-control'>"
    row.insertCell(3).innerHTML = "<input name='purchase[items][][sub_cost]' class='form-control'>"

  }

}
