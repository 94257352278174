import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "items"];

  static values = {
    itemizable: String
  };

  connect() {
  }

  addAssociation() {
    var items = this.itemsTarget.querySelectorAll(".nested-fields").length;
    if (items == 2) {
      return;
    }
    var content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      items
    );
    this.templateTarget.insertAdjacentHTML("afterend", content);
    // this.addFieldValues();
    this.itemAdded()
  }

  removeAssociation(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
    this.computeTotal();
  }

  itemAdded(){
    if(this.itemizableValue == "Payment"){
      this.salePaymentController.splitPayment()
    }
  }

  get salePaymentController(){
    return this.application.getControllerForElementAndIdentifier(this.element, 'sale-payments')
  }
}

// TODO refactor purchase item nested fields to this controller
