import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["biller", "phoneError", "tel", "name", "email", "location", "company"];

  connect() {
  }

  get telController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "tel"
    );
  }

  async setPhoneNumber(event) {
    event.preventDefault();
    if (this.validatePhone(this.phone)) {
      const phoneNumber = this.phone;
      const customerExists = await this.checkCustomerExists(phoneNumber);

      if (customerExists) {
        this.phoneErrorTarget.textContent = "Customer already exists.";
        const customerData = await this.getCustomerData(phoneNumber);
        if (customerData) {
          this.nameTarget.value = customerData.name;
          this.emailTarget.value = customerData.email;
          this.locationTarget.value = customerData.location;
          this.companyTarget.value = customerData.company;
        }
      } else {
        this.phoneErrorTarget.textContent = "";
        this.telTarget.value = this.phone;
      }
    }
  } 

  phoneChanged(event) {
    event.preventDefault();

    if (event.target.value.charAt(0) === "0") {
      event.target.value = event.target.value.slice(1);
    }

    this.phone =
      this.telController.iti.selectedCountryData.dialCode + event.target.value;

    if (this.validatePhone(this.phone)) {
      event.target.classList.remove("border-danger");
      this.phoneErrorTarget.textContent = "";
    } else {
      event.target.classList.add("border-danger");
      this.phoneErrorTarget.textContent = "Invalid phone";
    }
  }

  validatePhone(phone) {
    if (phone && phone.length === 12) {
      return true;
    } else {
      return false;
    }
  }  

  checkBillers(biller_ids) {
    var billers = this.billerTarget.querySelectorAll("input[type=checkbox]");
    billers.forEach((biller) => {
      if (biller_ids.includes(parseInt(biller.value))) {
        biller.checked = true;
      }
    });
  }

  async checkCustomerExists(phoneNumber) {
    try {
      const response = await fetch(`/customer_check?phone=${phoneNumber}`, {
        method: "GET",
        headers: {
          "Accept": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        return data.exists;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  async getCustomerData(phoneNumber) {
    try {
      const response = await fetch(`/customer_details?phone=${phoneNumber}`, {
        method: "GET",
        headers: {
          "Accept": "application/json",
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        return data.customerDetails;
      }
    } catch (error) {
      console.error(error);
    }
  
    return null;
  }

}
