import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
  
      $('#subvariantsModal').on('show.bs.modal', function(event) {
        var button = $(event.relatedTarget);
        var variantId = button.data('variant-id');
        var productId = button.data('product-id');
  
        var modal = $(this);
        modal.find('.modal-title').text('Subvariants for ' + button.data('variant-name'));
        modal.find('.modal-body').html('<p>Loading...</p>');
  
        var url = '/products/' + productId + '/product_variants/' + variantId + '/sub_variant';
  
        $.ajax({
          url: url,
          method: 'GET',
          success: function(data) {
            modal.find('.modal-body').html(data);
          },
          error: function(jqXHR, textStatus, errorThrown) {
            console.error('Error fetching subvariants:', textStatus, errorThrown);
            modal.find('.modal-body').html('<p>Error loading subvariants. Please try again.</p>');
          }
        });
      });
    }
  }
  
