import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggleDate",  "dateField" ]

  connect() {
    this.toggleDateTarget.addEventListener("click", this.toggleDate.bind(this));
  }

  toggleDate() {
    this.dateFieldTarget.classList.toggle("d-none");
  }
}
