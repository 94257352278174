import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hide", "show", "cards"];

  showCards() {
    this.cardsTarget.classList.remove("d-none");
    this.showTarget.classList.add("d-none");
    this.hideTarget.classList.remove("d-none");
  }

  hideCards() {
    this.cardsTarget.classList.add("d-none");
    this.showTarget.classList.remove("d-none");
    this.hideTarget.classList.add("d-none");
  }
}
