import { Controller } from "@hotwired/stimulus"

import SlimSelect from "slim-select"

require("slim-select/dist/slimselect.min.css")


export default class extends Controller {
   static targets = [ "subCategory" ]

   connect(){
     this.select = new SlimSelect({
       select: this.subCategoryTarget,
       placeholder: 'Search account subcategory ',
       ajax: this.searchSubCategory,
       allowDeselectOption: true,
       searchFilter: (option, search) => {
         // result is already filtered
         return true
       }
     })
   }

    searchSubCategory(query, callback) {
     if (query.length < 3) {
       callback(false)
       return
     }
     Rails.ajax({
       type: "GET",
       url: `/search_sub_categories.json?q=${query}`,
       success:  (data, status, xhr)  => {
         let options = [];
         for (let i = 0; i < data.length; i++) {
           options.push({text: `${data[i].name}`, value: data[i].id.toString()})
         }
         callback(options)
       },
       error: callback(false)
     });
   }
}
