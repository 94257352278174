import { Controller } from "@hotwired/stimulus"

const LEAVING_MESSAGE = "Are you sure you want to leave this page, All changes unsaved will be lost"

export default class extends Controller {

  static targets = ["items"]

  connect(){
  }

  leavePage(event){
    if(this.hasItemsTarget){
      this.checkItemsLength()
    }

    if(this.isFormChanged()){
      if(event.type == "turbo:before-visit"){
        if(!window.confirm(LEAVING_MESSAGE)){
          event.preventDefault()
        }
      }else{
        event.returnValue = LEAVING_MESSAGE
        return event.returnValue
      }
    }
  }

  allowSubmissiom(){
    this.setChanged("false")
  }

  formIsChanged(event){
    event.preventDefault()
    this.setChanged("true")
  }

  setChanged(changed){
    this.data.set("changed", changed)
  }

  isFormChanged(){
    return this.data.get("changed") === "true"
  }

  checkItemsLength(){
    var items = this.itemsTarget.querySelectorAll(".nested-fields").length
    if(items > 0){
      this.setChanged("true")
    }
  }

}
