import { Controller } from "@hotwired/stimulus"

import SlimSelect from "slim-select"

require("slim-select/dist/slimselect.min.css")


// OPTIMIZE: dry out search with slim select
export default class extends Controller {
   static targets = [ "selectable" ]
   static values = {
      selectable: String,
      ajax: Boolean
    }

   connect(){
     this.select = new SlimSelect({
       select: this.selectableTarget,
       placeholder: `Search ${this.selectableValue}`,
       ajax: this.ajaxValue ? this.search : false,
       searchFilter: (option, search) => {
         // result is already filtered
         return true
       }
     })
   }

   search = (query, callback) => {

     if (query.length < 3) {
       callback(false)
       return
     }
     Rails.ajax({
       type: "GET",
       url: `/${this.selectableValue}.json?q=${query}`,
       success:  (data, status, xhr) => {
         let options = [];
         for (let i = 0; i < data.length; i++) {
           var name = this.selectableValue == "suppliers" ? data[i].company : data[i].name
           var phone = this.selectableValue == "suppliers" ? '' : data[i].phone
           var location = this.selectableValue == "suppliers" ? '' : data[i].location
           options.push({text: `${name} - ${phone} - ${location}`, value: data[i].id.toString()})
         }
         callback(options)
       },
       error: callback(false)
     });
   }
}
