import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["productId"];

  static values = {
    productId: String,
  };

  showStock() {
    Rails.ajax({
      type: "GET",
      url: `/stocks/${this.productId()}`,
      dataType: "script",
    });
  }

  productId() {
    if (this.hasProductIdTarget) {
      return this.productIdTarget.value;
    } else {
      return this.productIdValue;
    }
  }
}
