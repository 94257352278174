import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["caretRight", "caretDown", "childCategories"]

  connect() {
    this.toggleCaretIcons()
  }

  toggleCaretIcons() {
    this.caretRightTarget.classList.toggle("d-none")
    this.caretDownTarget.classList.toggle("d-none")
  }

  toggleChildCategories() {
    this.childCategoriesTarget.classList.toggle("d-none")
    this.toggleCaretIcons()
  }
}