import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "biller",
    "cash",
    "mobile",
    "cheque",
    "card",
    "total",
    "expenses",
    "cashAtHand",
    "sales",
    "pending",
    "deposits",
    "direct",
    "dueCash",
    "dueMobile",
    "dueCheque",
    "dueCard",
    "dueDirect",
    "dueTotal",
    "todayTotal",
    "deposit",
    "walletDeposits",
    "walletDepositsTotal",
  ];

  lauchSummary(event) {
    this.getSummary();
  }

  billerChanged(event) {
    event.preventDefault();
    this.getSummary();
  }

  assignValues(data) {
    this.cashTarget.textContent = data.sales.cash || 0.0;
    this.mobileTarget.textContent = data.sales.mobile_money || 0.0;
    this.chequeTarget.textContent = data.sales.cheque || 0.0;
    this.cardTarget.textContent = data.sales.credit_card || 0.0;
    this.directTarget.textContent = data.sales.direct_transfer || 0.0;
    this.depositTarget.textContent = data.sales.customer_wallet || 0.0;

    // Due
    this.dueCashTarget.textContent = data.due_sales.cash || 0.0;
    this.dueMobileTarget.textContent = data.due_sales.mobile_money || 0.0;
    this.dueChequeTarget.textContent = data.due_sales.cheque || 0.0;
    this.dueCardTarget.textContent = data.due_sales.credit_card || 0.0;
    this.dueDirectTarget.textContent = data.due_sales.direct_transfer || 0.0;

    var due_payment_total = Object.values(data.due_sales).reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0.0
    );

    var today_payment_total = Object.values(data.sales).reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0.0
    );

    this.dueTotalTarget.textContent = due_payment_total;
    this.todayTotalTarget.textContent = today_payment_total;

    this.totalTarget.textContent = due_payment_total + today_payment_total;

    this.expensesTarget.textContent = Object.values(data.expenses).reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0.0
    );
    this.salesTarget.textContent = data.sales_total || 0;
    this.pendingTarget.textContent = data.sales_total - today_payment_total;

    var deposits = []
    Object.keys(data.wallet_deposits).forEach((key, index) => {
      deposits.push(`${key}: ${data.wallet_deposits[key]}`)
    })

    this.walletDepositsTarget.textContent = deposits.join(", ")

    this.walletDepositsTotalTarget.textContent = Object.values(
        data.wallet_deposits
    ).reduce((a, b) => parseFloat(a) + parseFloat(b), 0.0);

    this.cashAtHandTarget.textContent =
        parseFloat(data.sales.cash || 0) +
        parseFloat(data.due_sales.cash || 0) +
        parseFloat(data.wallet_deposits.cash || 0) -
        parseFloat(data.expenses.cash || 0);
  }

  getSummary() {
    Rails.ajax({
      type: "GET",
      url: `/daily_summaries?biller=${this.billerTarget.value}`,
      success: (data, status, xhr) => {
        this.assignValues(data);
      },
    });
  }
}
