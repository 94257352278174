import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";
import "slim-select/dist/slimselect.min.css";

export default class extends Controller {
  static targets = ["selectable"];
  static values = {
    selectable: String,
    ajax: Boolean,
  };

  connect() {
    this.select = new SlimSelect({
      select: this.selectableTarget,
      placeholder: `Search ${this.selectableValue}`,
      ajax: this.ajaxValue ? this.search : false,
    });
  }

  search = (query, callback) => {
    if (query.length < 3) {
      callback(false);
      return;
    }

    Rails.ajax({
      type: "GET",
      url: `/${this.selectableValue}.json?q=${query}`,
      success: this.handleSearchSuccess(callback),
      error: this.handleSearchError(callback),
    });
  };

  handleSearchSuccess = (callback) => (data) => {
    const options = data.map((item) => this.formatOption(item));
    callback(options);
  };

  handleSearchError = (callback) => () => {
    callback(false);
  };

  formatOption(item) {
    const reference = item.reference || 'N/A';
    const customerName = item.customer_name || 'N/A';
    const customerPhone = item.customer_phone || 'N/A';

    return {
      text: `${reference} - ${customerName} - ${customerPhone}`,
      value: item.id.toString(),
    };
  }
}

