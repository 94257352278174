import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    warehouses: Array,
  };

  variantsChanged(event) {
    var template = this.cloneController.templateTarget;
    var variants = this.tomSelectController.select.items;
    var last_variant = variants.pop();

    if (last_variant) {
      this.warehousesValue.forEach((warehouse, i) => {
        this.cloneController.append({
          warehouse_id: warehouse[0],
          ware_house: warehouse[1],
          name: last_variant,
        });
      });
    }
  }

  get tomSelectController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "tom-select"
    );
  }

  get cloneController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "clone"
    );
  }
}
