import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["items", "template", "warehouse"];

  connect() {}

  addTableRow() {
    event.preventDefault();

    var product = this.productSelectController.productTarget;
    this.getProduct(product.value);
  }

  get productSelectController() {
    // https://github.com/hotwired/stimulus/issues/35
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "product-select"
    );
  }

  get varinatsController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "variants"
    );
  }

  addAssociation() {
    var content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
      // a proper :index value is needed
    );
    this.templateTarget.insertAdjacentHTML("afterend", content);
    this.addFieldValues();
  }

  addFieldValues() {
    var items = this.itemsTarget.querySelectorAll(".nested-fields");
    var item = items[0];
    item.querySelector("input[name*='product_id']").value = this.product.id;
    item.querySelector("input[name*='product_name']").value = this.product.name;
    item.querySelector("input[name*='quantity']").value = 1;

    // Add varinats
    if(this.product.variants.length > 0){
      this.addVariants(item)
    }

  }

  remove_association(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
  }

  addVariants(item) {
    var selectVariant = item.querySelector("select[name*=variants]");
    selectVariant.innerHTML = "";

    var filtered_variants = this.product.variants.filter((variant, i) => {
      return variant.warehouse_id == this.warehouseTarget.value;
    });

    filtered_variants.forEach((variant, i) => {
      selectVariant.innerHTML +=
        '<option value ="' + variant.id + '">' + variant.name + "</option>";
    });

    // add variantvalues with first variant
    var variant = filtered_variants[0];
    if (variant) {
      this.addVariantValues(item, variant.id, "(" + variant.name + ")");
    }
  }

  addVariantValues(item, value, text) {
    item.querySelector("input[name*='product_variant_id']").value = value;
    var value = item.querySelector("input[name*='product_name']").value;

    if (/\(.*\)/.test(value)) {
      item.querySelector("input[name*='product_name']").value = value.replace(
        /\(.*\)/,
        text
      );
    } else {
      item.querySelector("input[name*='product_name']").value =
        value + " " + text;
    }
  }

  getProduct(id) {
    // TODO: persist and use result data from searchProducts
    const self = this;
    Rails.ajax({
      type: "GET",
      url: `/products/${id}.json`,
      success: function (data, status, xhr) {
        self.product = data;
        self.addAssociation();
      },
    });
  }
}
