import { Controller } from "@hotwired/stimulus";
import { optionsForElement } from "dropzone";

const packageValues = [];
let label = ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90'];

export default class extends Controller {
    static targets = [
        "slider", 
        "volume",
        "actualVolume",
        "estimationDetails",
        "option",
        "length",
        "width",
        "height",
        "unitType",
        "weight",
        "volumetricWeight"
    ];

    connect() {
        console.log("Slider");
        this.getPackages().then(() => {
          this.addMarker();
        });
        this.toggleSlider();
        this.setupEventListeners();
    }

    setupEventListeners() {
        const lengthInput = this.lengthTarget;
        const widthInput = this.widthTarget;
        const heightInput = this.heightTarget;

        lengthInput.addEventListener('input', () => {
            this.toggleSlider();
        });

        widthInput.addEventListener('input', () => {
            this.toggleSlider();
        });

        heightInput.addEventListener('input', () => {
            this.toggleSlider();
        });
    }

    toggleSlider() {
      const sliderInput = this.sliderTarget;
      const lengthValue = this.lengthTarget.value.trim();
      const widthValue = this.widthTarget.value.trim();
      const heightValue = this.heightTarget.value.trim();

      console.log(`Length: ${lengthValue}, Width: ${widthValue}, Height: ${heightValue}`);
  
      if (sliderInput) {
          if (lengthValue || widthValue || heightValue) {
              console.log('Disabling slider');
              sliderInput.disabled = true;
          } else {
              console.log('Enabling slider');
              sliderInput.disabled = false;
          }
      }
  }
  

    addMarker() {
      const sortedValues = packageValues[0].sort((a, b) => parseFloat(a.volume) - parseFloat(b.volume));

      const lastObject = sortedValues.length - 1;
      const highestValue = sortedValues[lastObject].volume;
      console.log('highest value', highestValue)
      const optionDataList = this.optionTarget;
      const packageValuesLength = sortedValues.length;

      sortedValues.forEach((value, index) => {
        if (index < packageValuesLength) {
          while (this.optionTarget.childElementCount == sortedValues.length) {
            this.optionTarget.removeChild(this.optionTarget.firstChild);
          }
    
          const optionElement = document.createElement('option');
          optionElement.value = label[index];
          optionElement.label = String(value.volume);
          optionDataList.appendChild(optionElement);
        }
      });
    }
    
    moveSlider() {
      const volume = this.actualVolumeTarget.value;
      const nearestValue = this.findNearestValue(volume);

      this.sliderChange(nearestValue);
      this.volumeTarget.value = nearestValue;
      this.addVolumeDetails(nearestValue);
    }

    handleSliderChange() {
      const value = parseInt(this.sliderTarget.value);
      console.log('Slider Value:', value);
    
      let sliderIndexValue = label.indexOf(String(value));
      console.log('Slider Index:', sliderIndexValue);
    
      if (sliderIndexValue >= 0 && sliderIndexValue < packageValues[0].length) {
        let derivedValue = packageValues[0][sliderIndexValue];
        const nearestValue = (parseFloat(derivedValue.volume));
        this.volumeTarget.value = nearestValue;
        this.actualVolumeTarget.value = nearestValue;
        this.addVolumeDetails(nearestValue);
      } else {
        console.error("Invalid slider value or package data");
      }
    }

    addVolumeDetails(value) {
      while (this.estimationDetailsTarget.firstChild) {
          this.estimationDetailsTarget.removeChild(this.estimationDetailsTarget.firstChild);
      }
    
      const matchingPackage = packageValues[0].find(packageObj => parseFloat(packageObj.volume) === value);
      
      if (matchingPackage) {
          const listInfo = document.createElement('strong');
          listInfo.textContent = "Estimated volume size:";
          listInfo.classList.add("p-2");
          this.estimationDetailsTarget.appendChild(listInfo);

          matchingPackage.size_comparison.forEach(comparison => {
              const listItem = document.createElement('li');
              listItem.textContent = `Size comparison: ${comparison}`;
              listItem.classList.add("p-2");
              this.estimationDetailsTarget.appendChild(listItem);
          });
      }
  }

    sliderChange(value) {
        this.optionTarget.value = value;
        const optionValues = this.optionTarget.children;

        optionValues.forEach((option) => {
            if (parseFloat(option.label) === value) {
                const nearestValue = option.value;
                const inputEvent = new Event('input', { bubbles: true });
                this.sliderTarget.value = nearestValue;
                this.sliderTarget.dispatchEvent(inputEvent);
            }
        })
        this.compareWeights();
    }

    compareWeights() {
        const volumetricWeight = parseFloat(this.volumetricWeightTarget.value);
        const actualWeight = parseFloat(this.weightTarget.value);
        const compareWeight = document.getElementById("compareWeights");

        if (!isNaN(volumetricWeight) && !isNaN(actualWeight)) {
            if (volumetricWeight > actualWeight) {
                compareWeight.innerHTML = 'Use volumetric weight for packaging'
            } else if (volumetricWeight < actualWeight) {
                compareWeight.innerHTML = 'Use product dead weight for packaging'
            } else {
                console.log('Both weights are equal');
            }
        } else if (!isNaN(volumetricWeight)) {
            compareWeight.innerHTML = 'Only volumetric weight present, use this for packaging';
        } else if (!isNaN(actualWeight)) {
            compareWeight.innerHTML = 'Only product dead weight present, use this for packaging';
        } else (
            console.log('neither weights is present')
        )
    }

    findNearestValue(value) {
        const packageVolumes = packageValues[0].map(obj => parseFloat(obj.volume));
        return packageVolumes.reduce(function(prev, curr) {
          return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
        });
    }

    calculateVolume() {
        const length = parseFloat(this.lengthTarget.value) || 0;
        const width = parseFloat(this.widthTarget.value) || 0;
        const height = parseFloat(this.heightTarget.value) || 0;
        let volume = 0;

        const unitType = this.unitTypeTarget.value;
        console.log('unitType', unitType);

        switch (unitType) {
            case "Centimeters":
                volume = (length / 100) * (width / 100) * (height / 100);
                break;
            case "Millimeters":
                volume = (length / 1000) * (width / 1000) * (height / 1000);
                break;
            default:
                volume = length * width * height;
        }

        volume = Math.abs(volume) % 1 !== 0 ? volume.toFixed(4) : volume;

        this.volumeTarget.value = volume;
        this.actualVolumeTarget.value = volume;
        this.calculateVolumetricWeight(volume);
    }

    calculateVolumetricWeight(value) {
        const volumetric_weight = value * 166.7;
        const cbm_weight = Math.abs(volumetric_weight) % 1 !== 0 ? volumetric_weight.toFixed(4) : volumetric_weight

        this.volumetricWeightTarget.value = cbm_weight;
    }

    getPackages() {
        return new Promise((resolve, reject) => {
          Rails.ajax({
            type: "GET",
            url: `/packages`,
            success: (data, status, xhr) => {
              for (let i = 0; i < data.length; i++) {
                packageValues.push(data);
              }
              resolve();
            },
            error: (xhr, status, error) => {
              reject(error);
            }
          });
        });
    }
}
