import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox"];
  
  connect() {
    console.log("Connected");
  }
  // TODO: Fix error of getting ID
  toggle() {
    const checkbox = this.checkboxTarget;
    const url = checkbox.dataset.resourceUrl;
    const value = checkbox.checked;
  
    const params = new FormData();
    params.append("account[ecommerce_feature]", value);
    params.append("ecommerce_courier[status]", value);
  
    Rails.ajax({
      url,
      type: "PATCH",
      data: params,
      success: () => {
        console.log("ToggleBooleanFieldController: Successfully updated");
      },
      error: (err) => {
        console.error("ToggleBooleanFieldController: Failed to update", err);
      },
    });
  }  
}