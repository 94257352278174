import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "name", "slug" ]

  connect() {
  }

  nameChange(event){
    event.preventDefault()
    this.slugTarget.value = this.nameTarget.value.toLowerCase()
  }
}
