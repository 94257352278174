import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['blacklistReasonNote', 'recommendationNote'];

  connect() {
    this.toggleFields();
  }
  
  toggleFields() {
    const isChecked = this.element.querySelector('#blacklisted').checked;
    this.blacklistReasonNoteTarget.style.display = isChecked ? 'block' : 'none';
    this.recommendationNoteTarget.style.display = isChecked ? 'block' : 'none';
  }
}