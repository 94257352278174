import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["featureBlock", "promoBlock"];
  static values = { showClass: String, hideClass: String };

  connect() {
    this.showClassValue = this.showClassValue || "d-block";
    this.hideClassValue = this.hideClassValue || "d-none";
    this.toggleFeatureBlocks();
    this.togglePromoBlock();
  }

  toggleFeatureBlocks() {
    const featureOnEcommerce = this.element.querySelector("input[name='product[feature_on_ecommerce]']:checked");
    const isFeatureOnEcommerce = featureOnEcommerce.value === "true";
    this.featureBlockTargets.forEach(block => {
      block.classList.toggle(this.showClassValue, isFeatureOnEcommerce);
        block.classList.toggle(this.hideClassValue, !isFeatureOnEcommerce);
    });
  }

  togglePromoBlock() {
    const promoOnEcommerce = this.element.querySelector("input[name='product[promotion]']:checked");
    const isPromoOnEcommerce = promoOnEcommerce.value === "true";
    this.promoBlockTargets.forEach(block => {
        block.classList.toggle(this.showClassValue, isPromoOnEcommerce);
            block.classList.toggle(this.hideClassValue, !isPromoOnEcommerce);
        });
  }
}
