import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["reasonSelect", "reasonExplanation"];

  connect() {
    this.showExplanation();
  }

  showExplanation() {
    const selectedReason = this.reasonSelectTarget.value.toLowerCase();
    const explanation = this.getExplanation(selectedReason);

    this.reasonExplanationTarget.textContent = explanation;
  }

  getExplanation(reason) {
    switch (reason) {
      case "outgoing-stock":
        return "This reason is selected for the purpose of fulfilling customer sales / invoices";
      case "internal-movement":
        return "This transfer involves moving stock within the business. These transfers may involve rearranging inventory to improve efficiency, or moving items to prepare for upcoming stock replenishment.";
      default:
        return "";
    }
  }
}

