import { Controller } from "@hotwired/stimulus";
import { TemplateInstance } from "@github/template-parts";

export default class extends Controller {
  static targets = ["template", "items"];

  append(values) {
    if (values) {
      var id = { id: this.itemsSize() };
      this.itemsTarget.append(
        this.appendValues({ ...values, ...id }).cloneNode(true)
      );
    } else {
      this.itemsTarget.append(this.appendIndex(template).cloneNode(true));
    }
  }

  itemsSize() {
    return this.itemsTarget.querySelectorAll(".item").length;
  }

  appendIndex(content) {
    return new TemplateInstance(content, {
      id: this.itemsTarget.querySelectorAll(".item").length,
    });
  }

  appendValues(values) {
    return new TemplateInstance(this.templateTarget, values);
  }
}
