import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [ "statusField", "stmtType" ];
  
    connect() {
      this.toggleStatusField()
    }
  
    stmtTypeChanged() {
      this.toggleStatusField()
    }
  
    toggleStatusField() {
      if (this.stmtTypeTarget.value === "Sales") {
        this.statusFieldTarget.classList.remove("d-none")
      } else {
        this.statusFieldTarget.classList.add("d-none")
      }
    }
  }