import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['cloneable'];

  addClone() {
    const clone = this.cloneableTarget.cloneNode(true);
    clone.classList.add('clone');
    this.cloneableTarget.parentNode.appendChild(clone);
  }
}