import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["durationField", "fromDateField", "toDateField", "checkbox"];

  connect() {
    this.hideDateFields();
  }

  toggleDateFields() {
    if (this.checkboxTarget.checked) {
      this.showDateFields();
      this.hideDurationField();
    } else {
      this.hideDateFields();
      this.showDurationField();
    }
  }

  showDateFields() {
    this.fromDateFieldTarget.style.display = "block";
    this.toDateFieldTarget.style.display = "block";
  }

  hideDateFields() {
    this.fromDateFieldTarget.style.display = "none";
    this.toDateFieldTarget.style.display = "none";
  }

  showDurationField() {
    this.durationFieldTarget.style.display = "block";
  }

  hideDurationField() {
    this.durationFieldTarget.style.display = "none";
  }
}
  