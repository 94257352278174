import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["billerSummary", "billerSelector"]

  connect() {
    this.filterSummaries();
  }

  filterSummaries() {
    const selectedBillerId = this.billerSelectorTarget.value;

    // Show all summaries if "All Billers" is selected or if the summary belongs to the selected biller
    this.billerSummaryTargets.forEach((summary) => {
      if (selectedBillerId === "" || summary.dataset.billerId === selectedBillerId) {
        summary.style.display = "block";
      } else {
        summary.style.display = "none";
      }
    });
  }
}
