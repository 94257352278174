import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = [
        "activeUsers", 
        "inactiveUsers", 
        "allAgents", 
        "allAdmins", 
        "allUsers",
        "prompt"
    ];

    connect(){
        console.log("Appointment")
    }

    validateAllUsers(){
        console.log("Validate?")
        console.log(this.allUsersTarget.checked);
        console.log(this.allAgentsTarget.checked);
        if (this.allUsersTarget.checked) {
            this.activeUsersTarget.checked = false;
            this.inactiveUsersTarget.checked = false;
            this.allAgentsTarget.checked = false;
            this.allAdminsTarget.checked = false;
            this.promptTarget.classList.remove("d-none");
        } else {
            this.promptTarget.classList.add("d-none");
        }
    }

    validate(){
        console.log("Active");
        if (this.activeUsersTarget.checked || this.inactiveUsersTarget.checked || this.allAdminsTarget.checked || this.allAgentsTarget.checked) {
            this.allUsersTarget.checked = false;
            this.promptTarget.classList.add("d-none");
        }
    }
}