import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["countType", "partialSection", "ecommerceStock", "countPost", "warehouseId"]

  connect() {
    console.log('connected')
  }

  countTypeChanged(event) {
    event.preventDefault();
    this.partialSectionTarget.classList.toggle("d-none");
  }

  ecommerceStockChanged() {
    this.countTypeTarget.classList.toggle("d-none", this.ecommerceStockTarget.checked);
  }

  countPostChanged() {
    const userList = document.getElementById('userList');
    userList.style.display = this.countPostTarget.checked ? 'block' : 'none';
  }

  warehouseIdChanged() {
    const userList = document.querySelector('#userList');
    const warehouseId = this.warehouseIdTarget.options[this.warehouseIdTarget.selectedIndex].value;
  
    if (!warehouseId) {
      userList.innerHTML = '';
      return;
    }
  
    const orderedList = document.createElement('ol');
    const url = `/users_from_warehouse?warehouse_id=${warehouseId}`;
  
    fetch(url)
      .then(response => response.json())
      .then((users) => {
        userList.innerHTML = '';
  
        const heading = document.createElement('h3');
        heading.textContent = 'Users';
        userList.appendChild(heading);
  
        users.forEach(user => {
          const userElement = document.createElement('li');
          userElement.innerHTML = `
            ${user.first_name} ${user.last_name}
            <div>
              <input type="checkbox" id="count_and_post_${user.id}" name="stock_count_permission[${user.id}][can_count_and_post]">
              <label for="count_and_post_${user.id}">Count & Post</label>
            </div>
            <div>
              <input type="checkbox" id="update_final_stock_${user.id}" name="stock_count_permission[${user.id}][can_update_final_stock]">
              <label for="update_final_stock_${user.id}">Update Final Stock</label>
            </div>
          `;
          orderedList.appendChild(userElement);
        });
  
        userList.appendChild(orderedList);
      })
  }
}
