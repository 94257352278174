import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["reasonAlert", "reason", "recommendationAlert", "recommendation"]

  
  checkBlacklisted(event) {
    const customerId = event.target.value;
    if (customerId) {
      Rails.ajax({
        type: "GET",
        url: `/customers/${customerId}.json`,
        success: (data) => {
          if (data.blacklisted) {
            this.reasonAlertTarget.classList.remove("d-none");
            this.reasonTarget.textContent = data.blacklist_reason_note_content;
            this.recommendationAlertTarget.classList.remove("d-none");
            this.recommendationTarget.textContent = data.recommendation_note_content;
          } else {
            this.reasonAlertTarget.classList.add("d-none");
            this.recommendationAlertTarget.classList.add("d-none");
          }
        },
        error: () => {
          console.error("Error fetching customer data");
        }
      });
    } else {
      this.reasonAlertTarget.classList.add("d-none");
      this.recommendationAlertTarget.classList.add("d-none");
    }
  }

  dismissReasonAlert() {
    this.reasonAlertTarget.classList.add("d-none");
  }

  dismissRecommendationAlert() {
    this.recommendationAlertTarget.classList.add("d-none");
  }
}