import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "mandatory", "message" ]

  displayMessage() {
    if (!this.mandatoryTarget.checked) {
      this.messageTarget.classList.remove('d-none')
      this.messageTarget.textContent = "This option will reverse Ecommerce Quantity to Reqular Quantity."
    } else {
      this.messageTarget.classList.add('d-none')
      this.messageTarget.textContent = ""
    }
  }
}
