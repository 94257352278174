import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["password", "confirmation", "error"];

  validate() {
    const passwordValue = this.passwordTarget.value;
    const confirmationValue = this.confirmationTarget.value;

    if (passwordValue.length < 6) {
      this.errorTarget.innerText = "Password must be at least 6 characters long";
    } else if (passwordValue !== confirmationValue) {
      this.errorTarget.innerText = "Passwords do not match";
    } else {
      this.errorTarget.innerText = "";
    }
  }
}